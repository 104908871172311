<template>
  <div>
    <div>
      <v-card :elevation="0" outlined :loading="loading">
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-card :elevation="0" outlined>
                <v-card-title class="primary">
                  Not Voted List
                  <v-btn
                    title
                    color="success"
                    class="ml-2"
                    @click="getData"
                    elevation="0"
                  >
                    <v-icon color="white">mdi-refresh</v-icon>
                    Refresh
                  </v-btn>
                </v-card-title>

                <el-form
                  :rules="rules"
                  ref="form"
                  label-width="120px"
                  label-position="top"
                  class="demo-ruleForm"
                >
                  <div class="row">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                      <el-form-item label="ID Card No" prop="id_card_no">
                        <el-input
                          size="mini"
                          v-model="id_card_no"
                          @keyup.enter.native="searchByIdCard"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                      <el-form-item label="Search" prop="search">
                        <el-input
                          size="mini"
                          v-model="search"
                          @keyup.enter.native="searchByKeyword"
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                      <el-form-item label="Ballot Box" prop="ballot_boxes_id">
                        <select-list
                          v-model="ballot_boxes_id"
                          :extraid="campaigns_id"
                          @change="ballot_box_changed"
                          :readonly="true"
                          :list="ballotBoxes"
                          :customlist="true"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
                <v-card-text>
                  <el-table
                    :data="data"
                    @sort-change="sortChange"
                    empty-text="No Voters"
                    :default-expand-all="true"
                    style="width: 100%"
                  >
                    <el-table-column label="Photo" prop="photo" width="150">
                      <template slot-scope="props">
                        <object
                          :data="imUrl + 'members/' + props.row.photo"
                          width="50px"
                          type="image/png"
                        >
                          <img
                            class="rounded"
                            :src="no_img"
                            style="width: 50px; height: 50px"
                            alt="Memeber Photo"
                          />
                        </object>
                      </template>
                    </el-table-column>
                    <el-table-column
                      sortable
                      label="ID Card No"
                      prop="id_card_no"
                      width="130"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.id_card_no }}
                        <br />
                        <el-alert
                          v-if="showVoteStatus"
                          :title="scope.row.status"
                          style="padding: 3px"
                          :type="
                            scope.row.status == 'Yes Vote'
                              ? 'success'
                              : scope.row.status == 'No Vote'
                              ? 'error'
                              : 'warning'
                          "
                          effect="dark"
                          :closable="false"
                        ></el-alert>
                      </template>
                    </el-table-column>
                    <el-table-column
                      sortable
                      width="350px"
                      label="Full Name"
                      prop="full_name"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.full_name }} / {{ scope.row.gender }}
                        <br />
                        <el-alert
                          :title="
                            (!empty(scope.row.consit_code)
                              ? scope.row.consit_code + ' - '
                              : '') + scope.row.consit_name
                          "
                          type="warning"
                          :closable="false"
                          v-if="!empty(scope.row.consit_name)"
                        ></el-alert>
                      </template>
                    </el-table-column>
                    <el-table-column
                      sortable
                      width="120px"
                      label="Mobile"
                      prop="mobile_number"
                    >
                      <template slot-scope="props">
                        <span>{{ props.row.mobile_number }}</span>
                        <i
                          v-if="props.row.mobile_verified == true"
                          class="el-icon-success text--green"
                        ></i>
                      </template>
                    </el-table-column>
                    <el-table-column
                      sortable
                      width="120px"
                      label="House"
                      prop="address"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      width="120px"
                      label="Ballot Box"
                      prop="code"
                    ></el-table-column>
                    <el-table-column
                      sortable
                      width="250px"
                      label="Ballot Box Name"
                      prop="ballot_box"
                    ></el-table-column>
                  </el-table>
                  <el-pagination
                    @size-change="sizeChanged"
                    @current-change="getData"
                    :current-page.sync="currentPage"
                    :page-sizes="[10, 25, 50, 100, 200, 300, 400]"
                    :page-size="perPage"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    class="mb-3"
                  ></el-pagination>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Button, Card, Input, InputNumber, Alert } from "element-ui";
Vue.use(Card);
Vue.use(Button);
import {
  Table,
  TableColumn,
  Pagination,
  Option,
  Select,
  Form,
  FormItem,
} from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Alert);
import SelectList from "@/components/custom/SelectList.vue";
export default {
  created() {
    this.getData();
    this.getBallotBoxes();
    this.getConstituencys();
  },
  components: {
    SelectList,
  },
  data() {
    return {
      valid: true,
      loading: false,
      campaigns_id: 5,
      data: [],
      search: "",
      currentPage: 1,
      perPage: 10,
      total: 0,
      sort: "full_name|asc",
      end_point: "public/",
      houses_id: "",
      islands_id: "",
      id_card_no: "",
      rules: {},
      view: "view",
      ballot_boxes_id: null,
      consituencys_id: null,
      constituencys: [],
      ballotBoxes: [],
    };
  },
  methods: {
    searchByIdCard() {
      this.houses_id = "";
      this.search = "";
      this.getData();
    },
    searchByKeyword() {
      this.houses_id = "";
      this.id_card_no = "";
      this.getData();
    },
    ballot_box_changed(item) {
      if (!this.empty(item)) {
        this.ballot_boxes_id = item.id;
        this.getData();
      } else {
        this.ballot_boxes_id = null;
        this.getData();
      }
    },
    island_changed(item) {
      if (!this.empty(item)) {
        this.islands_id = item.id;
        this.getData();
      } else {
        this.islands_id = null;
        this.getData();
      }
    },
    consit_changed(item) {
      if (!this.empty(item)) {
        this.constituency = item.name;
        this.getData();
      } else {
        this.constituency = null;
        this.getData();
      }
    },
    getData() {
      this.data = [];
      this.loading = true;
      let f = {
        email: this.email,
        password: this.password,
        id_card_no: this.id_card_no,
        mobile_no: this.mobile_no,
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
        ballot_boxes_id: this.ballot_boxes_id,
        islands_id: this.islands_id,
        houses_id: this.houses_id,
        constituency: this.constituency,
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-not-voted-list", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.data = response.data.data;
            this.total = response.data.total;
            this.permissions = response.data.permissions;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getConstituencys() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "get-constituencys", f)
        .then((response) => {
          this.loading = false;
          this.constituencys = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getBallotBoxes() {
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api(this.end_point + "my-ballot-boxes", f)
        .then((response) => {
          this.loading = false;
          this.ballotBoxes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sizeChanged(page) {
      this.perPage = page;
      this.getData();
    },
    fitlerData() {
      console.log(this.formFilter);
      this.currentPage = 1;
      this.getData();
    },
    sortChange(col) {
      this.sort = col.prop + "|" + (col.order == "ascending" ? "asc" : "desc");
      this.getData();
    },
  },
  name: "NotVotedList",
  computed: {
    allownew() {
      return (
        this.permissions.filter((e) => e.permission == "Create").length > 0
      );
    },
    allowEdit() {
      return this.permissions.filter((e) => e.permission == "Edit").length > 0;
    },
    allowDisable() {
      return (
        this.permissions.filter((e) => e.permission == "Disable").length > 0
      );
    },
    showVoteStatus() {
      return (
        this.permissions.filter((e) => e.permission == "Show Vote Status")
          .length > 0
      );
    },
  },
};
</script>
<style>
.el-table__expanded-cell {
  background: gold;
  padding-left: 10px !important;
}
</style>
